<template>
    <div class="check-payment__page">
      <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>
      <h1>Перевірка статусу оплати для замовлення №{{ orderReference }}</h1>
      <p v-if="statusMessage">{{ statusMessage }}</p>
    </div>
  </template>
  
  <script>
  import paymentService from '@/api/paymentService';
  
  export default {
    name: "CheckPaymentPage",
    data() {
      return {
        isLoading: true,
        statusMessage: "",
        intervalId: null,
      };
    },
    computed: {
      orderReference() {
        return this.$route.params.slug;
      }
    },
    methods: {
      async checkPaymentStatus() {
        console.log("Починаємо перевірку статусу оплати для замовлення:", this.orderReference);
        try {
          const response = await paymentService.checkPaymentStatus({ orderReference: this.orderReference, first_check: false });
          console.log("Відповідь сервера на перевірку статусу:", response);
  
          const transactionStatus = response.transactionStatus || response.status[0];
          console.log("Отриманий статус:", transactionStatus);
  
          // Обробка отриманого статусу
          if (transactionStatus === 'Approved') {
            this.statusMessage = "Оплата успішна! Переходимо на сторінку підтвердження замовлення...";
            console.log("Статус: Approved");
            this.stopChecking(); // Зупиняємо перевірку
            this.redirectAfterDelay('OrderSuccessPage');
          } else if (['InProcessing', 'Pending', 'WaitingAuthComplete'].includes(transactionStatus)) {
            this.statusMessage = "Очікуємо підтвердження по вашому платежу...";
            console.log("Статус: Очікування підтвердження");
            // Спінер продовжує обертатися і статус перевіряється кожні 7 секунд
          } else {
            this.statusMessage = "Оплата неуспішна. Повернення на сторінку підтвердження...";
            console.log("Статус: Оплата неуспішна");
            this.stopChecking(); // Зупиняємо перевірку
            this.redirectAfterDelay('OrderSuccessPage', 5000);
          }
        } catch (error) {
          console.error("Помилка при перевірці статусу оплати:", error);
          this.statusMessage = "Помилка під час перевірки статусу. Повторіть спробу пізніше.";
          this.stopChecking(); // Зупиняємо перевірку при помилці
        } finally {
          this.isLoading = false;
          console.log("Завершення перевірки статусу, зупиняємо спінер");
        }
      },
      redirectAfterDelay(routeName, delay = 3000) {
        setTimeout(() => {
          console.log(`Перенаправлення на маршрут: ${routeName} через ${delay} мс`);
          this.$router.push({ name: routeName });
        }, delay);
      },
      startChecking() {
        console.log("Початок періодичної перевірки статусу оплати");
        this.checkPaymentStatus();
        this.intervalId = setInterval(this.checkPaymentStatus, 7000);
      },
      stopChecking() {
        if (this.intervalId) {
          console.log("Зупинка періодичної перевірки статусу оплати");
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    },
    mounted() {
      this.startChecking();
    },
    beforeUnmount() {
      this.stopChecking();
    }
  };
  </script>
  
  <style scoped>
  .check-payment__page {
    text-align: center;
    position: relative;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  