<template>
    <div class="order__form">
        <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
        </div>
      <!-- Крок 1: Персональна інформація -->
      <form v-if="currentStep === 1" @submit.prevent="goToDeliveryStep" class="form validate personal-info__form">
        <span class="title">Персональна інформація</span>
        <div class="fields">
          <label>
            <input 
              v-model="orderData.first_name" 
              type="text" 
              placeholder="Ім'я" 
              required 
              :class="{ 'is-invalid': errors.first_name }" 
              @blur="validateField('first_name')"
            />
            <span v-if="errors.first_name" class="error-msg">Будь ласка, введіть ваше ім'я.</span>
          </label>
          <label>
            <input 
              v-model="orderData.last_name" 
              type="text" 
              placeholder="Прізвище" 
              required 
              :class="{ 'is-invalid': errors.last_name }" 
              @blur="validateField('last_name')"
            />
            <span v-if="errors.last_name" class="error-msg">Будь ласка, введіть ваше прізвище.</span>
          </label>
          <label>
            <input 
              v-model="orderData.father_name" 
              type="text" 
              placeholder="По-батькові"
            />
          </label>
          <label>
            <input 
              v-model="orderData.phone" 
              type="text" 
              placeholder="Телефон" 
              required 
              :class="{ 'is-invalid': errors.phone }" 
              @blur="validateField('phone')"
            />
            <span v-if="errors.phone" class="error-msg">Будь ласка, введіть номер телефону.</span>
          </label>
          <label>
            <input 
              v-model="orderData.email" 
              type="email" 
              placeholder="Email (для квитанції)" 
              :class="{ 'is-invalid': errors.email }" 
              @blur="validateField('email')"
            />
            <span v-if="errors.email" class="error-msg">Будь ласка, введіть email.</span>
          </label>
        </div>
        <div class="order_button grid-hd">
          <button type="submit" class="btn btn-primary">Додати інформацію про доставку</button>
        </div>
      </form>

      <!-- Крок 2: Інформація про доставку та оплата -->
      <form v-if="currentStep === 2" @submit.prevent="submitOrder" class="form validate delivery-info__form">
        <span class="title">Доставка і оплата</span>
        <div class="fields">
          <span class="subtitle">Виберіть спосіб доставки</span>
          <ul class="delivery-types">
            <li class="delivery-types__item" @click="selectDelivery('newpost')" :class="{ selected: orderData.deliveryType === 'newpost' }">
              <a href="javascript:void(0)">
                <img src="@/assets/img/newpost.png" alt="Нова пошта" />
                <span>Нова пошта</span>
              </a>

            </li>
            <li class="delivery-types__item" @click="selectDelivery('curier')" :class="{ selected: orderData.deliveryType === 'curier' }">
              <a href="javascript:void(0)">
                <img src="@/assets/img/courier.png" alt="Кур'єр" />        
                <span>Кур'єром</span>
              </a>
      
            </li>
          </ul>

          <!-- Поля для вибраного способу доставки -->
          <div v-if="orderData.deliveryType === 'curier'" class="delivery__type-form">
            <label>Місто: Київ</label>
            <label><input v-model="orderData.curier_info.street" type="text" placeholder="Вулиця" required /></label>
            <label><input v-model="orderData.curier_info.house" type="text" placeholder="Дім" required /></label>
            <label><input v-model="orderData.curier_info.appartment" type="text" placeholder="Квартира / Офіс" /></label>
          </div>

          <div v-else-if="orderData.deliveryType === 'newpost'" class="delivery__type-form">
            <label><input v-model="orderData.np_info.city" type="text" placeholder="Місто отримання" required /></label>
            <label><input v-model="orderData.np_info.branch" type="text" placeholder="Номер відділення" required /></label>
          </div>

          <span class="subtitle">Виберіть спосіб оплати</span>
          <p v-if="deliveryInfoMessage" class="info-text">{{ deliveryInfoMessage }}</p>

          <div class="delivery-types check_payment">
            <button 
                @click="selectPayment('card')" 
                :class="{ 'selected': orderData.payment === 'card' }"
                type="button"
            >
                Оплата карткою
            </button>
            <button 
                v-if="orderData.deliveryType === 'curier'" 
                @click="selectPayment('cash')" 
                :class="{ 'selected': orderData.payment === 'cash' }"
                type="button"
            >
                Готівкою
            </button>
            <button 
                v-if="orderData.deliveryType === 'newpost'" 
                @click="selectPayment('prepayment')" 
                :class="{ 'selected': orderData.payment === 'prepayment' }"
                type="button"
            >
                Передоплата
            </button>
          </div>

          
          <p class="info-text">Загальна сума: {{ totalOrderAmount }} грн.</p>
          <button type="submit" class="btn btn-primary" :disabled="!canSubmitOrder">Оформити замовлення</button>
        </div>
      </form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import orderService from '@/api/orderService';

export default {
  name: "OrderForm",
  data() {
    return {
      currentStep: 1,
      orderData: {
        first_name: "",
        last_name: "",
        father_name: "",
        phone: "",
        email: "",
        deliveryType: "newpost",
        np_info: {
          city: '',
          branch: '',
        },
        curier_info: { 
          city: 'Kyiv',
          street: '',
          house: '',
          appartment: '',
        },
        payment: "card",
        deliveryCost: "",
      },
      isOrderSuccess: false,
      isLoading: false,
      errors: {},
      baseDeliveryCost: 150,
      freeDeliveryThreshold: 4000,
    };
  },
  computed: {
    ...mapGetters('orders', ['order']),
    ...mapGetters({
      isWhoosaler: 'auth/isWhoosaler',
      cartTotalPrice: 'cart/cartTotalPrice',
      cartItems: 'cart/cartItems'
    }),

    orderAmount() {
      return this.cartTotalPrice;
    },
    
    deliveryCost() {
      if (this.orderData.deliveryType === "curier" && this.orderAmount < this.freeDeliveryThreshold) {
        return this.baseDeliveryCost;
      } else if (this.orderData.deliveryType === "newpost" && this.orderData.payment === "card") {
        return 0;
      } else if (this.orderData.deliveryType === "newpost" && this.orderData.payment === "prepayment") {
        return 0;
      }
      return 0;
    },

    totalOrderAmount() {
      return this.orderAmount + this.deliveryCost;
    },

    canSubmitOrder() {
      return (
        this.orderData.deliveryType &&
        this.orderData.payment &&
        (this.orderData.deliveryType === "curier" ? 
          this.orderData.curier_info.street && this.orderData.curier_info.house : 
          this.orderData.np_info.city && this.orderData.np_info.branch
        )
      );
    },

    deliveryInfoMessage() {
      if (this.orderData.deliveryType === "curier" && this.orderAmount >= this.freeDeliveryThreshold) {
        return "Доставка безкоштовна";
      } else if (this.orderData.deliveryType === "newpost" && this.orderData.payment === "card") {
        return "При повній оплаті карткою доставка Новою Поштою безкоштовна.";
      }else if (this.orderData.deliveryType === "newpost" && this.orderData.payment === "prepayment") {
        return "Накладений платіж по передоплаті 200 грн.";
      }
      return "При замовленні від 4000 грн,- доставка безкоштовка";
    },
  },
  methods: {
    ...mapActions('orders', ['createdOrder', 'setOrder']),
    goToDeliveryStep() {
      if (this.validateForm()) {
        this.currentStep = 2;
      } else {
        this.validateField('first_name');
        this.validateField('last_name');
        this.validateField('phone');
        this.validateField('email');
      }
    },
    validateField(field) {
      const value = this.orderData[field];
      switch (field) {
        case 'first_name': {
          this.errors.first_name = !value ? "Будь ласка, введіть ваше ім'я." : "";
          break;
        }
        case 'last_name': {
          this.errors.last_name = !value ? "Будь ласка, введіть ваше прізвище." : "";
          break;
        }
        case 'phone': {
          const phoneRegex = /^[+]?\d{9,}$/; // Дозволяє опціональний "+" та від 9 цифр
          this.errors.phone = !value
            ? "Будь ласка, введіть номер телефону."
            : !phoneRegex.test(value)
            ? "Введіть дійсний номер телефону, який містить лише числа і може починатися з +."
            : "";
          break;
        }
        case 'email': {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          this.errors.email = !value
            ? "Будь ласка, введіть email."
            : !emailRegex.test(value)
            ? "Введіть дійсну електронну адресу."
            : "";
          break;
        }
        default:
          break;
      }
    },
    validateForm() {
      this.errors = {
        first_name: !this.orderData.first_name,
        last_name: !this.orderData.last_name,
        phone: !this.orderData.phone,
        email: !this.orderData.email,
      };
      return !Object.values(this.errors).some((error) => error);
    },
    selectDelivery(type) {
      this.orderData.deliveryType = type;
      this.orderData.payment = ""; // Reset payment selection on delivery change
    },
    selectPayment(type) {
      this.orderData.payment = type;
    },
    async submitOrder() {
    this.isLoading = true; // Увімкнення спінера та затемнення
    try {
      const orderDetails = {
       
        ...this.orderData,
        deliveryCost: this.deliveryCost,
        totalAmount: this.totalOrderAmount,
        isWholesale: this.isWhoosaler, 
        cartItems: this.cartItems,
      };
   
      const createdOrder = await orderService.createOrder(orderDetails);
         console.log(this.deliveryCost),
      this.setOrder(createdOrder);
      console.log()
      console.log('Замовлення успішно створене:', createdOrder);
      
      // Перевірка типу оплати
      if (this.orderData.payment === 'cash') {
        this.isOrderSuccess = true; // Позначка успіху
        // Перехід на сторінку успіху
        console.log(createdOrder.id );
        this.$router.push({ name: 'OrderSuccessPage' });
      } else {
        // Додаткова логіка для оплати карткою (описана нижче)
        this.$router.push({ name: 'PaymentPage' });
      }
    } catch (error) {
      console.error('Помилка при створенні замовлення:', error);
    } finally {
      this.isLoading = false; // Виключення спінера після завершення
    }
  },}
};
</script>



<style scoped>


.payment-options button.selected {
  border-color: var(--clr-3);
}
  .order__form {
    display: grid;
    grid-gap: 32px;
    width: 420px;
    padding: 24px;
    background-color: whitesmoke;
    border-radius: 4px;
    position: relative;
  }
 
  .order__form .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  
  .personal-info__form .fields,
  .delivery__type-form .fields {
    display: grid;
    grid-gap: 16px;
  }
  
  .delivery-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin-bottom: 40px;
  }
  @media(max-width:550px){
    .delivery-types {
      display: flex;
      gap: 30px;
      flex-direction: column;
  }
  .delivery-types__item a{
    align-items: center;
    justify-content: flex-start;
  }
}
  
  .delivery-types__item a {
    border: 2px solid rgb(228, 228, 228);
    border-radius: 4px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .delivery-types__item a span {
    font-size: 20px;
    margin: -25px;
  }
  .delivery-types__item.selected a {
    border-color: var(--clr-1);
    background-color: #99ecff50;
  }
  .delivery-types__item a img {
    position: absolute;
    display: block;
    height: 100px;
    object-position: center;
    object-fit: contain;
    margin: 8px;
}
  .error-msg {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .is-invalid {
    border-color: red;
  }
  @media (max-width: 1250px) {
    .order__form{
        width: calc(100% - 50px);
    }
  }
  .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.check_payment button {
  padding: 26px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  background-color: var(--clr-2) !important;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.check_payment button.selected {
  border-color: var(--clr-3);
  background-color: var(--clr-3) !important;
}

  </style>
  

