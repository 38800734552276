import axios from 'axios';
import store from '@/store';
import router from '@/router';

const apiClient = axios.create({
    baseURL: 'https://fortisbeauty.com.ua/api/v1',
    // baseURL: 'http://127.0.0.1:8000/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Інтерцептор для додавання токена до кожного запиту
apiClient.interceptors.request.use(
    (config) => {
        const token = store.state.auth.accessToken;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Інтерцептор для обробки 401 помилки
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        
        // Логування помилки
        console.log("Інтерцептор отримав помилку:", error.response.status);

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Виклик дії refreshAccessToken для оновлення accessToken
                console.log("Оновлення токена...");
                await store.dispatch('auth/refreshAccessToken');

                // Оновлений токен
                const newToken = store.state.auth.accessToken;
                if (newToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    console.log("Токен оновлено успішно. Повторний запит...");
                    return apiClient(originalRequest);
                } else {
                    console.log("Новий токен не отримано.");
                    throw new Error("Не вдалося оновити токен");
                }
            } catch (refreshError) {
                console.log("Помилка при оновленні токена:", refreshError);
                store.dispatch('auth/logout');
                router.push('/login');
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;
