<template>
  <div class="order__success">
    <div class="content">
      <h1>Дякуємо за замовлення! Наш менеджер зв‘яжеться з Вами найближчим часом для підтвердження.</h1>
      <p v-if="localOrderData" class="order-number">Номер вашого замовлення: {{ localOrderData.id }}</p>
      <div v-if="cartItemsLocal.length" class="cart-details">
        <h2>Ваше замовлення:</h2>
        <ul>
          <li v-for="item in cartItemsLocal" :key="item.id">
            <img :src="item.image" :alt="item.name" class="item-image" />
            <span class="item-name">{{ item.name }}</span>
            <span class="item-quantity">Кількість: {{ item.quantity }}</span>
            <span class="item-price">Ціна: {{ item.price }} грн</span>
          </li>
        </ul>
      </div>

      <div id="delivery">
        <img
          width="120"
          height="120"
          src="@/assets/img/opt_success.png"
          alt="Успішне замовлення"
          class="delivery-animation"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "OrderSuccess",
  data() {
    return {
      cartItemsLocal: [], // Локальний стан для збереження даних кошика
      localOrderData: {}, // Локальна копія даних замовлення
    };
  },
  computed: {
    ...mapGetters({
      cartItems: 'cart/cartItems',
      orderData: 'orders/order',
    }),
    orderId() {
      return this.$route.params.orderId; 
    },
    totalPrice() {
      return this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    }
  },
  methods: {
    ...mapActions({
      clearCart: 'cart/clearCart',
      clearOrder: 'orders/clearOrders'
    }),
    pushPurchaseEvent() {
      if (window.dataLayer) {
        const products = this.cartItemsLocal.map(item => ({
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: item.quantity,
          code: item.code,
        }));

        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: this.localOrderData.id, // Номер замовлення з локальної копії
                revenue: this.totalPrice,  // Загальна сума замовлення
              },
              products: products,
            },
          },
        });
      }
    }
  },
  mounted() {
    // Зберігаємо поточний стан кошика у локальну змінну перед очищенням
    this.cartItemsLocal = [...this.cartItems];
    
    // Зберігаємо поточні дані замовлення у локальну змінну перед очищенням
    this.localOrderData = { ...this.orderData };

    // Викликаємо подію покупки
    this.pushPurchaseEvent();

    // Очищаємо кошик і дані замовлення в Vuex
    this.clearCart();
    this.clearOrder();
  }
};
</script>

  <style scoped>
  /* Стилі залишаються ті ж */
  .order__success {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .content {
    max-width: 700px;
    padding: 30px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
  }
  
  h1 {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 16px;
  }
  
  .order-number {
    font-size: 18px;
    color: #555;
    margin-top: 12px;
    font-weight: bold;
  }
  
  .cart-details {
    text-align: left;
    margin-top: 20px;
  }
  
  .cart-details h2 {
    font-size: 22px;
    margin-bottom: 12px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .item-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .item-name {
    flex-grow: 1;
    font-size: 16px;
    color: #333;
  }
  
  .item-quantity, .item-price {
    font-size: 16px;
    color: #666;
  }
  
  #delivery {
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    height: 120px;
  }
  
  .delivery-animation {
    position: absolute;
    bottom: 0;
    right: -150px;
    transform: scaleX(-1);
    animation: drive-left 3s ease-in-out forwards;
  }
  
  @keyframes drive-left {
    0% {
      transform: translateX(0) scaleX(-1);
    }
    100% {
      transform: translateX(-100vw) scaleX(-1);
    }
  }
  </style>
  