<template>
  <div class="home">
    <!-- Десктопна версія -->
    <HomeSlider v-if="!isMobile" />

    <!-- Categories -->
    <div class="home__categories" v-if="!isMobile">
      <div class="content">
        <p class="title1">Категорії</p>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">
              <img :src="category.image" :alt="category.name" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Products (Новинки) -->
    <ProductsSlider :heading="'Новинки'" :products="products" 
    @OpenWishList="openWish"
    @OpenCartList="openCart"
    
    />

    <!-- About Section -->
    <HomeAbout :slogan="slogan" :logo="logo" :advantages="advantages" />

    <!-- Мобільна версія -->
    <HomeSlider v-if="isMobile" />
    
    <!-- Categories -->
    <div class="home__categories" v-if="isMobile">
      <div class="content">
        <p class="title1">Категорії</p>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">
              <img :src="category.image" :alt="category.name" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSlider from '@/components/HomeSlider.vue';
import ProductsSlider from '@/components/ProductsSlider.vue';
import HomeAbout from '@/components/HomeAbout.vue';
import { fetchLatestProducts } from '@/api/productService';

export default {
  name: 'HomePage',
  components: {
    HomeSlider,
    ProductsSlider,
    HomeAbout,
  },
  data() {
    return {
      isMobile: false,
      products: [],
      categories: [
        { id: 16, name: "Косметика для обличчя", url: "/catalogue/oblichchia/", image: 
        "https://api.fortisbeauty.com.ua/media/shop/categories/image-oblichchia-id-16_s.webp", human: "Косметика для обличчя" },
        { id: 29, name: "Косметика для волосся", url: "/catalogue/volossia/", image: 
        "https://api.fortisbeauty.com.ua/media/shop/categories/image-volossia-id-29_s.webp", human: "Косметика для волосся" },
        { id: 163, name: "Догляд для чоловіків", url: "/catalogue/dogliad-dlia-cholovikiv/", image: 
        "https://api.fortisbeauty.com.ua/media/shop/categories/image-dogliad-dlia-cholovikiv-id-163_s.webp", human: "Догляд для чоловіків" },
        { id: 165, name: "Косметика для тіла", url: "/catalogue/tilo/", image: 
        "https://api.fortisbeauty.com.ua/media/shop/categories/image-tilo-id-165_s.webp", human: "Косметика для тіла" }
      ],
      logo: require('@/assets/img/logo.png'),
      advantages: [
        { title: 'Перевага 1', image: 'https://api.fortisbeauty.com.ua/media/main/ouradvantagespc/image-id-7.png' },
        { title: 'Перевага 2', image: 'https://api.fortisbeauty.com.ua/media/main/ouradvantagespc/image-id-8.png' },
        { title: 'Перевага 3', image: 'https://api.fortisbeauty.com.ua/media/main/ouradvantagespc/image-id-9.png' },
        { title: 'Перевага 2', image: 'https://api.fortisbeauty.com.ua/media/main/ouradvantagespc/image-id-16.png' },
        { title: 'Перевага 3', image: 'https://api.fortisbeauty.com.ua/media/main/ouradvantagespc/image-id-11.png' }
      ]
    };
  },
  mounted() {
    this.checkIfMobile();
    this.loadLatestProducts();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    openWish(){
        this.$emit('OpenWishList');
      },
    openCart(){
        this.$emit('OpenCartList');
      },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    async loadLatestProducts() {
      try {
        const response = await fetchLatestProducts();
        this.products = response.data.map(product => ({
          id: product.id,
          name: product.name,
          brand: product.brand.name,
          human: product.human,
          url: `/product/${product.slug}`, // Використовуйте відповідний URL
          variants: product.variant.map(variant => ({
            id: variant.id,
            code: variant.code,
            value: variant.value,
            price: variant.price,
            discount_price: variant.discount_price,
            images: variant.images,
            url: variant.url,
            whoosale_price: variant.whoosale_price,
            discount_whoosale_price: variant.discount_whoosale_price,
            stock: variant.stock,
            stock_opt: variant.stock_opt,
          })),
        }));
      } catch (error) {
        console.error("Помилка завантаження новинок:", error);
      }
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/home/home.css';
</style>
