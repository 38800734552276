<template>
  <div class="content">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs">
      <router-link to="/">Головна</router-link>
      <span class="slash">/</span>
      <p>Співпраця</p>
    </div>
  </div>
  <div class="content opt">

    <!-- БАНЕР -->
    <div class="swiper-container home-slider">
      <div class="swiper-slide home-slider__slide">
        <img
          v-if="banner.pc"
          class="lazy banner__pc loading"
          :src="banner.pc.image"
          alt="Opt Banner PC"
        />
        <img
          v-if="banner.mobile"
          class="lazy banner__mobile"
          :src="banner.mobile.image"
          alt="Opt Banner Mobile"
        />
      </div>
    </div>

    <!-- ОПИС ПРОПОЗИЦІЇ -->
    <h1>Пропозиція оптовому покупцю</h1>
    <div class="richtext" v-html="pageDescription"></div>

    <!-- КНОПКА ПЕРЕХОДУ ДО КАТАЛОГУ -->
    <a class="button" href="/registration/business">Зареєструватись як оптовий покупець</a>
  </div>

</template>

<script>
import { fetchBannerOpt } from '@/api/bannerService';

export default {
  name: 'OptPage',
  data() {
    return {
      banner: {},
      pageDescription: `
        <p>Привіт, ми — Fortis Beauty, сервіс, завдяки якому б'юті бізнес вилітає на орбіту! В чому секрет? В емпатії😉 Ми створили потужний супорт для клієнтів, який дозволяє отримувати 60% доходу та забути про всі складнощі, з якими стикаються конкуренти. З нами комфортно, бо ми все робимо вчасно та з любов‘ю до людей. Аналізуємо ринок, відбираємо топові бренди та продукти, пропонуємо відтермінування платежу, програми лояльності, закриваємо питання доставки…</p>
        <p>А сьогодні — пропонуємо бренди, яких немає в Україні. Бренди, на які Україна чекала. Офіційно! Acnemy, Tranparent Lab, HAIRVEST, Hyalulip. Ми розширяємо асортимент, Ви — отримуєте привілеї на ринку.</p>
      `
    };
  },
  mounted() {
    this.loadBanner();
  },
  methods: {
    async loadBanner() {
      try {
        const response = await fetchBannerOpt();
        this.banner = response.data[0]; 
      } catch (error) {
        console.error('Помилка при завантаженні банера:', error);
      }
    },
  },
};
</script>

<style scoped>
.opt {
    position: relative;
    display: block;
    max-width: 960px;
    margin: 0 auto 48px auto;
}
.opt .button {
    margin-top: 64px;
}


.home-slider {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 40px;
}
.home-slider__slide {
    position: relative;
    padding-top: calc(100% / 3);
    background-color: whitesmoke;
}

.banner__pc,
.banner__mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  top: 0;
}

.banner__mobile {
  display: none;
}

@media (max-width: 640px) {
  .home-slider__slide {
    padding-top: 200%; /* Співвідношення 2:1 для мобільного */
  }

  .banner__pc {
    display: none;
  }

  .banner__mobile {
    display: block;
  }}

</style>