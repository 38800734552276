<!-- src/views/BlogList.vue -->

<template>
  <div class="content">
    <!-- BREADCRUMBS -->
    <ul class="breadcrumbs">
      <li><router-link to="/">Головна</router-link></li>
      <li><span class="slash">/</span><span>Статті</span></li>
    </ul>

    <!-- BLOG LIST -->
    <div class="content blog">
      <ul class="blog__post__list">
        <li v-for="post in posts" :key="post.id" class="blog__post">
          <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }" class="image">
            <div class="image__wrapper">
              <img :src="post.image" :alt="post.name" />
            </div>
          </router-link>
          <div class="info">
            <div class="name">{{ post.name }}</div>
            <div class="date">{{ post.date }}</div>
            <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }" class="button">Докладніше</router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { fetchBlogPosts } from '@/api/blogService';

export default {
  name: 'BlogList',
  data() {
    return {
      posts: [],
    };
  },
  mounted() {
    this.loadPosts();
  },
  methods: {
    async loadPosts() {
      try {
        const response = await fetchBlogPosts();
        this.posts = response.data;
      } catch (error) {
        console.error('Помилка завантаження постів:', error);
      }
    },
  },
};
</script>

  
<style scoped>
.blog__post__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 24px;
}
.blog__post {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid rgb(238, 238, 238);
}
.blog__post .image__wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 66%;
    overflow: hidden;
}
.blog__post .image__wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-position: center;
    object-fit: cover;
    transition: all 0.6s ease-in-out;
}
.blog__post:hover {
    border-color: var(--clr-1);
}
.blog__post:hover img{
    transform: scale(1.15);
}
.blog__post .info {
    padding: 24px;
}
.blog__post .info .name {
    position: relative;
    display: block;
    font-size: var(--h4);
    font-weight: 500;
    margin-bottom: 8px;
}
.blog__post .info .date {
    font-size: var(--h6);
    font-weight: 500;
    color: grey;
}

.blog__post .button {
    margin-top: 24px;
}
</style>
  