    <template>
        <div class="content">
            <ul class="breadcrumbs">
                <li><router-link to="/">Головна</router-link></li>
                <li> 
                  <span class="slash"> > </span>
                  <router-link to="/catalogue">Каталог</router-link>
                </li>

                <li>
                    <span class="slash"> > </span>
                    <router-link :to="`/catalogue/${product.category?.parent_slug}`">{{ product.category.parent_name }}</router-link>
                </li> 
                <li>
                    <span class="slash"> > </span>
                    <router-link :to="`/catalogue/${product.category?.parent_slug}/${product.category?.slug}`">{{ product.category.name }}</router-link>
                </li>
                <li>
                    <span class="slash"> > </span>
                    <span>{{ product.human }}</span>
                </li>

            </ul>
            <Product :product="product" 
            @OpenWishList="openWish"
            @OpenCartList="openCart"
            />
        </div>
    </template>
    
<script>
import { fetchProductBySlug } from '@/api/productService'
import Product from '@/components/Product/ProductComponent.vue'

    
export default {
    name: 'ProductPage',
    components: {
        Product,
    },
    data() {
    return {
        product: {},
    }
    },
    methods: {
      openWish(){
        this.$emit('OpenWishList');
      },
      openCart(){
        this.$emit('OpenCartList');
      },
      pushProductViewDataLayer() {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'productView',
          ecommerce: {
            detail: {
              products: [{
                id: this.product.id,
                name: this.product.name,
                price: this.product.selectedVariant?.price || this.product.price,
                discount_price: this.product.selectedVariant?.discount_price || null,
                code: this.product.selectedVariant?.code || this.product.code,
              }],
            },
          },
        });
      }
    }
    },
    async created() {
    try {
    const slug = this.$route.params.slug
    const response = await fetchProductBySlug(slug)
    this.product = response.data;

    // Виклик pushProductViewDataLayer після завантаження продукту
    this.pushProductViewDataLayer();
    } catch (error) {
    console.error("Error fetching product by slug:", error);
    }
    },
    
    }
</script>
    
<style scoped>

.breadcrumbs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 48px;
}
.breadcrumbs span {
    color: grey;
    margin: 0 4px;
}
.breadcrumbs span.slash {
    color: grey;
}
.breadcrumbs * {
    font-size: var(--h6);
}
@media(max-width: 960px) {
  .breadcrumbs {
    padding-bottom: 24px;
  }
  .breadcrumbs * {
    font-size: var(--h5);
  }
  .content {
    width: calc(100% - 60px);;
  }
}
</style>
    
    