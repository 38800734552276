<template>
  <form @submit.prevent="submitForm" class="contact-form">
    <div class="fields">
      <p>
        <input
          v-model="form.subject"
          :class="{ 'invalid': errors.subject }"
          type="text"
          name="subject"
          maxlength="255"
          placeholder="Тема листа"
          required
        />
      </p>
      <p>
        <input
          v-model="form.first_name"
          :class="{ 'invalid': errors.first_name }"
          type="text"
          name="first_name"
          maxlength="255"
          placeholder="Ім'я"
          required
        />
      </p>
      <p>
        <input
          v-model="form.email"
          :class="{ 'invalid': errors.email }"
          type="email"
          name="email"
          maxlength="255"
          placeholder="Email"
          required
        />
      </p>
      <p>
        <input
          v-model="form.phone"
          :class="{ 'invalid': errors.phone }"
          type="text"
          name="phone"
          maxlength="255"
          placeholder="Телефон"
          required
        />
      </p>
      <p>
        <textarea
          v-model="form.text"
          :class="{ 'invalid': errors.text }"
          name="text"
          cols="40"
          rows="10"
          placeholder="Текст листа"
          required
        ></textarea>
      </p>
    </div>
    <button type="submit">Відправити</button>
  </form>
</template>

<script>
import apiClient from '@/api/axios';

export default {
  data() {
    return {
      form: {
        subject: '',
        first_name: '',
        email: '',
        phone: '',
        text: ''
      },
      errors: {
        subject: false,
        first_name: false,
        email: false,
        phone: false,
        text: false
      }
    };
  },
  methods: {
    validateForm() {
      this.errors.subject = !this.form.subject;
      this.errors.first_name = !this.form.first_name;
      this.errors.email = !this.form.email;
      this.errors.phone = !this.form.phone;
      this.errors.text = !this.form.text;

      return !Object.values(this.errors).includes(true);
    },
    async submitForm() {
      if (!this.validateForm()) {
        alert('Заповніть усі обов’язкові поля!');
        return;
      }

      try {
        await apiClient.post('/core/recal_me/', this.form);
        alert('Дякуємо! Ваше повідомлення надіслано!');
        this.clearForm();
        this.$emit('formSubmitted');
      } catch (error) {
        console.error('Помилка при відправці форми:', error);
        alert('Виникла помилка при надсиланні форми. Спробуйте ще раз.');
      }
    },
    clearForm() {
      this.form = { subject: '', first_name: '', email: '', phone: '', text: '' };
      this.errors = { subject: false, first_name: false, email: false, phone: false, text: false };
    }
  }
};
</script>

<style scoped>
.contact-form {
  display: grid;
  grid-auto-columns: auto;
  grid-gap: var(--px16);
  max-width: var(--px1140);
  height: calc(100% - 160px);
  grid-template-columns: repeat(1, 1fr);
}

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}
.fields p {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.fields p input,
.fields p textarea {
  width: calc(100% - 32px);
  padding: 16px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px var(--clr-1);
  font-size: var(--h5);
  transition: box-shadow 0.2s ease-in-out;
  outline: none;
}
.fields p.invalid input,
.fields p.invalid textarea {
  box-shadow: inset 0 0 0 2px #ff0000; /* Червона рамка для невалідних полів */
}
.fields p:last-of-type {
  grid-column: 1 / -1; /* textarea займе всю ширину сітки */
}
.fields p textarea {
  resize: vertical;
  font-family: inherit;
}

.contact-form button {
  cursor: pointer;
  padding: 16px;
  border: 0;
  border-radius: 4px;
  background-color: #ff2261 !important;
  width: 100% !important;
  color: #fff;
  margin-top: 24px;
  transition: all 0.2s ease-in-out;
  float: right;
}
</style>
