<template>
  <div class="wishlist">
    <h2>Список бажань</h2>
    <!-- Відображення індикатора, якщо список бажань порожній -->
    <div v-if="!wishlistItems.length" class="empty-message">
      <p>Ваш список бажань порожній.</p>
    </div>
    <!-- Відображення продуктів у вішлісті -->
    <ul v-else class="wishlist-items">
      <li v-for="item in wishlistItems" :key="item.code" class="wishlist-item">
        <div class="item-info">
          <a :href="item.url" class="item-image">
            <img :src="item.image" alt=""/>
          </a>
          <div class="item-details">
            <a :href="item.url" class="item-name">{{ item.name }}</a>
            <p class="item-price">{{ item.price }} грн</p>
          </div>
        </div>
        <span @click="removeFromWishlist(item.code)" class="remove">
          <img src="/img/Garbage.5e39d7b0.svg" alt="x" />
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'WishlistComponent',
  computed: {
    ...mapGetters('wishlist', ['wishlistItems']),
  },
  methods: {
    ...mapActions('wishlist', ['removeFromWishlist']),
  },
  mounted() {
    this.$store.dispatch('wishlist/fetchWishlist');
  },
};
</script>

<style scoped>
.wishlist {
  padding: 20px;
}
h2 {
  font-size: 24px;
  margin-bottom: 16px;
}
.empty-message {
  text-align: center;
  color: #666;
}
.wishlist-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.wishlist-item {
  display: flex;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
}
.item-info {
  display: flex;
  align-items: center;
}
.item-image img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
  margin-right: 12px;
}
.item-details {
  display: flex;
  flex-direction: column;
}
.item-name {
  font-weight: 600;
  margin-bottom: 4px;
  color: #333;
  text-decoration: none;
}
.item-name:hover {
  color: #007bff;
}
.item-price {
  color: #666;
}
.remove {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
}
.remove img {
  width: 24px;
  height: 24px;
}
.remove:hover {
  opacity: 0.7;
}
@media (max-width: 600px) {
  .wishlist-items {
    grid-template-columns: 1fr;
  }
}
</style>
