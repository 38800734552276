<template>
  <div class="data profile_data">
    <div class="personal__info forms">
      <ul>
        <li class="block user__personal-info">
          <input class="edit" type="checkbox" v-model="isEditing">
          <div class="heading">
            <p class="title2">Особисті дані</p>
            <label @click="toggleEditing">
              <img src="/static/img/icons/edit.png" alt="">
              <span v-if="!isEditing">Редагувати</span>
              <span v-else>Відмінити</span>
            </label>
          </div>
          <form @submit.prevent="updatePersonalInfo" class="form">
            <div class="fields">
              <div>
                <label for="first_name">Ім'я:</label>
                <p>
                  <input
                    type="text"
                    id="first_name"
                    v-model="formData.first_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="last_name">Прізвище:</label>
                <p>
                  <input
                    type="text"
                    id="last_name"
                    v-model="formData.last_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="father_name">По-батькові:</label>
                <p>
                  <input
                    type="text"
                    id="father_name"
                    v-model="formData.father_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="phone">Номер телефону:</label>
                <p>
                  <input
                    type="text"
                    id="phone"
                    v-model="formData.phone"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="country">Країна:</label>
                <p>
                  <input
                    type="text"
                    id="country"
                    v-model="formData.country"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="city">Місто:</label>
                <p>
                  <input
                    type="text"
                    id="city"
                    v-model="formData.city"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditing">Оновити</button>
          </form>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PersonalInfo',
  data() {
    return {
      isEditing: false,
      formData: {
        first_name: '',
        last_name: '',
        father_name: '',
        phone: '',
        country: '',
        city: '',
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  watch: {
    getUser: {
      immediate: true,
      handler(newUser) {
        if (newUser) {
          this.formData = { ...newUser };
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', ['fetchUser', 'updateUser']),
    async toggleEditing() {
      this.isEditing = !this.isEditing;
      if (this.isEditing) {
        this.formData = { ...this.getUser };
      }
    },
    async updatePersonalInfo() {
      try {
        await this.updateUser(this.formData); // Викликаємо updateUser через Vuex
        await this.fetchUser(); // Оновлюємо дані користувача після змін
        this.isEditing = false;
      } catch (error) {
        console.error("Помилка оновлення даних користувача:", error);
      }
    }
  },
  async mounted() {
    await this.fetchUser(); // Завантажуємо дані користувача при завантаженні
  }
};
</script>
