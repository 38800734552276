<!-- RegistrationConfirmation.vue -->
<template>
    <div class="content">
        <p class="h1 mt48 center">Дякуємо Вам за реєстрацію на нашому сайті.</p>
        <p class="h2 mt16 mb16 center">
            На Вашу електронну адресу 📬 було надіслано листа 📧 з посиланням на активацію. Перейдіть по ній для активації ✨ Вашого профілю.
        </p>
        <img
            style="width: 100%; max-width: 640px; display: block; margin: 32px auto; border-radius: 4px;"
            src="@/assets/img/sahmpoo.gif"
            alt="sahmpoo"
        />
        <a class="h2 color-2 block center mt16 mb16" href="/">Перейти на головну сторінку</a>
    </div>
</template>

<script>
export default {
    name: 'RegistrationConfirmation',
};
</script>

<style scoped>
</style>
