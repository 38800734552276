<template>
    <div :class="['sidebar menu', 'left', { active: isOpen }]" id="sidebar_menu">
      <div class="menu__header">
        <a href="/" class="logo">
          <img src="@/assets/img/logo.png" alt="FortisBeauty" />
        </a>
        <select name="set_language" @change="changeLanguage">
            <option value="ua" :selected="currentLanguage === 'ua'">UA</option>
            <option value="en" :selected="currentLanguage === 'en'">EN</option>
        </select>

        <div class="header__account">
          <a href="/profile">
            <img src="@/assets/img/icons/user/Avatar.svg" alt="Користувач" class="ico" />
          </a>
        </div>
        <div class="header__wishlist" @click="toggleWishlist">
            <img src="@/assets/img/icons/svg/Heart.svg" alt="Список побажань">
            <span class="header__qty header__wishlist-qty">0</span>
        </div>
      </div>
  
      <ul>
        <li class="group-title__wrapper catalogue">
            <span class="group-title">{{ $t('sidebar.catalogue') }}:</span>
        </li>
        <div class="tree">
            <ul class="root">
                
                    <li id="category-li-16">
    <span class="title">
        <a href="/catalogue/oblichchia/">{{ $t('sidebar.face') }}</a>
        
            <img src="@/assets/img/icons/arrow-next.png" alt=">">
        
    </span>
    
        <ul class="children">
            <li>
                <span class="title back">&lt; Назад до Обличчя</span>
            </li>
            
                
                    <li id="category-li-17">
    <span class="title">
        <a href="/catalogue/oblichchia/ochishchuvachi-ta-toneri/">Очищувачі та тонери</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-20">
    <span class="title">
        <a href="/catalogue/oblichchia/lokalni-zasobi/">Локальні засоби</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-23">
    <span class="title">
        <a href="/catalogue/oblichchia/pilingi/">Пілінги</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-24">
    <span class="title">
        <a href="/catalogue/oblichchia/nabori/">Набори</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-28">
    <span class="title">
        <a href="/catalogue/oblichchia/kremi/">Креми</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-31">
    <span class="title">
        <a href="/catalogue/oblichchia/dogliad-dlia-gub/">Догляд для губ</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-95">
    <span class="title">
        <a href="/catalogue/oblichchia/dogliad-dlia-ochei/">Догляд для очей</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-97">
    <span class="title">
        <a href="/catalogue/oblichchia/sirovatki/">Сироватки</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-100">
    <span class="title">
        <a href="/catalogue/oblichchia/maski/">Маски</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-154">
    <span class="title">
        <a href="/catalogue/oblichchia/oliyi/">Олії</a>
        
    </span>
    
</li>

                
            
        </ul>
    
</li>

                
                    <li id="category-li-29">
    <span class="title">
        <a href="/catalogue/volossia/">{{ $t('sidebar.hair') }}</a>
        
            <img src="@/assets/img/icons/arrow-next.png" alt=">">
        
    </span>
    
        <ul class="children">
            <li>
                <span class="title back">&lt; Назад до Волосся</span>
            </li>
            
                
                    <li id="category-li-299">
    <span class="title">
        <a href="/catalogue/volossia/vidnovlennia/">Відновлення</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-30">
    <span class="title">
        <a href="/catalogue/volossia/sirovatki/">Сироватки</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-173">
    <span class="title">
        <a href="/catalogue/volossia/konditsioneri/">Кондиціонери</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-184">
    <span class="title">
        <a href="/catalogue/volossia/shampuni/">Шампуні</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-185">
    <span class="title">
        <a href="/catalogue/volossia/losion/">Лосьйон</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-186">
    <span class="title">
        <a href="/catalogue/volossia/maski/">Маски</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-187">
    <span class="title">
        <a href="/catalogue/volossia/stailing/">Стайлінг</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-188">
    <span class="title">
        <a href="/catalogue/volossia/tekhnichni-produkti/">Технічні продукти</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-189">
    <span class="title">
        <a href="/catalogue/volossia/esentsialna-oliia/">Есенціальна олія</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-190">
    <span class="title">
        <a href="/catalogue/volossia/aktivator/">Активатор</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-192">
    <span class="title">
        <a href="/catalogue/volossia/nabir/">Набір</a>
        
    </span>
    
</li>

                
            
        </ul>
    
</li>

                
                    <li id="category-li-163">
    <span class="title">
        <a href="/catalogue/dogliad-dlia-cholovikiv/">{{ $t('sidebar.men_care')}}</a>
        
    </span>
    
</li>

                
                    <li id="category-li-165">
    <span class="title">
        <a href="/catalogue/tilo/">{{ $t('sidebar.body')}}</a>
        
            <img src="@/assets/img/icons/arrow-next.png" alt=">">
        
    </span>
    
        <ul class="children">
            <li>
                <span class="title back">&lt; Назад до Тіло</span>
            </li>
            
                
                    <li id="category-li-99">
    <span class="title">
        <a href="/catalogue/oblichchia/spreyi-dlia-tila/">Спреї для тіла</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-166">
    <span class="title">
        <a href="/catalogue/tilo/skrabi/">Скраби</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-168">
    <span class="title">
        <a href="/catalogue/tilo/dlia-vanni-ta-dushu/">Для ванни та душу</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-169">
    <span class="title">
        <a href="/catalogue/tilo/oliyi/">Олії</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-170">
    <span class="title">
        <a href="/catalogue/tilo/kremi/">Креми</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-175">
    <span class="title">
        <a href="/catalogue/tilo/nabori/">Набори</a>
        
    </span>
    
</li>

                
            
                
                    <li id="category-li-196">
    <span class="title">
        <a href="/catalogue/tilo/aromasvichki/">Аромасвічки</a>
        
    </span>
    
</li>

                
            
        </ul>
    
</li>

                
            </ul>
        </div>
        <li>
            <a class="item" href="/">{{ $t('sidebar.home')}}</a>
        </li>
       <!--
        <li>
            <a class="item" href="/cataloguediscount=yes/">Акції</a>
        </li> -->

        <li class="group-title">{{ $t('sidebar.partner') }}:</li>
            <li><a class="item" href="/opt/">{{ $t('sidebar.cooperation') }}</a></li>
            <li><a class="item" href="/core/partner/">{{ $t('sidebar.become_partner') }}</a></li>
        <li>
            <span class="group-title">Покупцеві:</span>
        </li>
        <li><a class="item" href="/brands/">{{ $t('sidebar.brands') }}</a></li>
        <li><a class="item" href="/contacts/">{{ $t('sidebar.contacts') }}</a></li>
        <li><a class="item" href="/about/">{{ $t('sidebar.about') }}</a></li>
        <li><a class="item" href="/blog/">{{ $t('sidebar.blog') }}</a></li>
        <li><a class="item" href="/delivery/">{{ $t('sidebar.delivery') }}</a></li>
        <li><a class="item" href="/povernenna/">{{ $t('sidebar.returns') }}</a></li>
        <li><a class="item" href="/payment/">{{ $t('sidebar.payment') }}</a></li>
        <li><a class="item" href="/terms-of-use/">{{ $t('sidebar.terms_of_use') }}</a></li>
        
       
        
       
    
    </ul>
    </div>
  </template>
  
<script>
export default {
  name: "MobileSidebar",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      required: true
    }
  },

  methods: {
    changeLanguage(event) {
      const selectedLang = event.target.value;
      this.switchLanguage(selectedLang);
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('user-locale', lang); 
    },
    toggleCategory(categoryId) {
      if (this.openCategories.includes(categoryId)) {
        // Якщо категорія відкрита, закриваємо її
        this.openCategories = this.openCategories.filter(id => id !== categoryId);
      } else {
        // Якщо закрита, відкриваємо
        this.openCategories.push(categoryId);
      }
    },
    isCategoryOpen(categoryId) {
      return this.openCategories.includes(categoryId);
    },
    toggleWishlist() {
        this.$emit('toggle-wishlist'); // WishList
    }
    
  },
  data() {
    return {
      openCategories: [],
      categories: [
        {
          id: 16,
          name: 'Обличчя',
          url: '/catalogue/oblichchia/',
          children: [
            { id: 17, name: 'Очищувачі та тонери', url: '/catalogue/oblichchia/ochishchuvachi-ta-toneri/' },
            { id: 20, name: 'Локальні засоби', url: '/catalogue/oblichchia/lokalni-zasobi/' },
            { id: 23, name: 'Пілінги', url: '/catalogue/oblichchia/pilingi/' }
            // інші дочірні елементи
          ]
        },
        {
          id: 29,
          name: 'Волосся',
          url: '/catalogue/volossia/',
          children: [
            { id: 299, name: 'Відновлення', url: '/catalogue/volossia/vidnovlennia/' },
            { id: 30, name: 'Сироватки', url: '/catalogue/volossia/sirovatki/' }
          ]
        },
      ]
    };
  }
};
</script>

  
  <style scoped>
  .sidebar {
    position: fixed;
    display: grid;
    grid-template-rows: 80px 1fr auto;
    width: 88%;
    max-width: 520px;
    height: 100%;
    top: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    z-index: 1000002;
    transition: left 0.2s ease-in-out;
    left: -440px;
  }
  
  .sidebar.active {
    left: 0;
  }
  
  .menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
  
  .menu__header img {
    width: 120px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar ul li a {
    text-decoration: none;
    color: black;
    font-size: 16px;
  }
  </style>
  