<template>
    <div class="content">
      <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>
      <div class="user_registration pb64">
        <p class="h1 center pb16">Реєстрація користувача</p>
        <form @submit.prevent="handleRegister" class="form partner_form" id="userForm" novalidate>
          <div class="fieldset userinfo">
            <p class="title f-headline">Персональна інформація</p>
            <ul class="fields">
              <li>
                <input
                  v-model="first_name"
                  type="text"
                  name="first_name"
                  maxlength="30"
                  placeholder="Ім'я *"
                  required
                  :class="{ 'input-error': isSubmitted && fieldErrors.first_name }"
                />
                <p class="msg" v-if="fieldErrors.first_name">{{ fieldErrors.first_name }}</p>
              </li>
              <li>
                <input
                  v-model="last_name"
                  type="text"
                  name="last_name"
                  maxlength="30"
                  placeholder="Прізвище *"
                  required
                  :class="{ 'input-error': isSubmitted && fieldErrors.last_name }"
                />
                <p class="msg" v-if="fieldErrors.last_name">{{ fieldErrors.last_name }}</p>
              </li>
              <li>
                <input
                  v-model="email"
                  type="email"
                  name="email"
                  maxlength="254"
                  placeholder="Email *"
                  required
                  :class="{ 'input-error': isSubmitted && fieldErrors.email }"
                />
                <p class="msg" v-if="fieldErrors.email">{{ fieldErrors.email }}</p>
              </li>
              <li>
                <input
                  v-model="phone"
                  type="text"
                  name="phone"
                  maxlength="40"
                  placeholder="Номер телефону *"
                  required
                  :class="{ 'input-error': isSubmitted && fieldErrors.phone }"
                />
                <p class="msg" v-if="fieldErrors.phone">{{ fieldErrors.phone }}</p>
              </li>
            </ul>
          </div>
  
          <div class="fieldset password">
            <p class="title f-headline">Пароль</p>
            <ul class="fields">
              <li class="password-field">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password1"
                  name="password1"
                  placeholder="Придумайте пароль"
                  required
                  :class="{ 'input-error': isSubmitted && fieldErrors.password1 }"
                />
                <span @click="togglePasswordVisibility" class="toggle-password">
                  {{ showPassword ? '🔓' : '🔒' }}
                </span>
                <p class="msg" v-if="fieldErrors.password1">{{ fieldErrors.password1 }}</p>
              </li>
              <li class="password-field">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password2"
                  name="password2"
                  placeholder="Пароль ще раз"
                  required
                  :class="{ 'input-error': isSubmitted && fieldErrors.password2 }"
                />
                <span @click="togglePasswordVisibility" class="toggle-password">
                  {{ showPassword ? '🔓' : '🔒' }}
                </span>
                <p class="msg" v-if="fieldErrors.password2">{{ fieldErrors.password2 }}</p>
              </li>
            </ul>
          </div>
  
          <p class="msg error" v-if="fieldErrors.general">{{ fieldErrors.general }}</p>
          <button type="submit" :disabled="isLoading">Зареєструватись</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import apiClient from '@/api/axios';
  
  export default {
    data() {
      return {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password1: '',
        password2: '',
        fieldErrors: {},
        isSubmitted: false,
        isLoading: false,
        showPassword: false,
      };
    },
    methods: {
      async handleRegister() {
        this.isLoading = true;
        this.isSubmitted = true;
        this.fieldErrors = {};
  
        if (this.password1 !== this.password2) {
          this.fieldErrors.password2 = 'Паролі не співпадають';
          this.isLoading = false;
          return;
        }
  
        try {
          // Відправка запиту на актуальний ендпоінт реєстрації
          const response = await apiClient.post('/auth/users/', {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone_number: this.phone,
            password: this.password1,
            re_password: this.password2,
          });
          
          console.log('Реєстрація успішна:', response);
          this.$router.push({ name: 'RegistrationConfirmation' });
        } catch (error) {
          console.error('Помилка реєстрації:', error);
          if (error.response?.data) {
            this.fieldErrors = error.response.data;
          } else {
            this.fieldErrors.general = 'Сталася помилка. Спробуйте ще раз.';
          }
        } finally {
          this.isLoading = false;
        }
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Оригінальні стилі та анімація завантаження */
  .fieldset {
    margin-top: 24px;
  }
  .fieldset ul.fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-top: 24px;
  }
  .input-error {
    border: 1px solid red;
  }
  .msg {
    color: red;
    font-size: 12px;
  }
  .user_registration {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding-top: 64px;
  }
  .fieldset ul.fields {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-top: 24px;
  }
  form.partner_form {
    grid-gap: 0px;
  }
  form .messges {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .password-field {
    position: relative;
  }
  .toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    color: #666;
  }
  </style>
  