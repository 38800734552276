<template>
  <div>
    <!-- BREADCRUMBS -->
    <div class="content">
      <div class="breadcrumbs">
        <router-link to="/">Головна</router-link>
        <span class="slash">/</span>
        <p>{{ page.title }}</p>
      </div>
    </div>

    <!-- MAIN PAGE CONTENT -->
    <div class="page">
      <div class="content">
        <img :src="page.banner || defaultImage" alt="Page Banner" />
        <!-- TITLE -->
        <p class="title1">{{ page.title }}</p>
        <div v-if="page.text" class="richtext" v-html="page.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchPageBySlug } from '@/api/pageService';

export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      page: {},
      defaultImage: require('@/assets/img/general_photo.jpg') 
    };
  },
  mounted() {
    this.fetchPageData();
  },
  watch: {
    slug: 'fetchPageData'
  },
  methods: {
    async fetchPageData() {
      try {
        const response = await fetchPageBySlug(this.slug);
        this.page = response.data[0];
        console.log(response.data);
      } catch (error) {
        console.error("Помилка завантаження сторінки:", error);
        this.page = { title: 'Сторінка не знайдена', text: 'Контент для цієї сторінки відсутній.' };
      }
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/pages/page.css';
</style>
