<template>
  <div class="content">
    <div class="user_registration pb64 registration__partner">
      <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>
      <p class="h1 center">Реєстрація партнера</p>
      <form @submit.prevent="handleRegister" class="form validate" novalidate>
        <!-- Особиста інформація -->
        <div class="fields personal">
          <div>
            <input
              v-model="email"
              type="email"
              placeholder="Email *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.email }"
            />
            <p class="msg" v-if="fieldErrors.email">{{ fieldErrors.email }}</p>
          </div>
          <div>
            <input
              v-model="phone"
              type="text"
              placeholder="Номер телефону *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.phone }"
            />
            <p class="msg" v-if="fieldErrors.phone">{{ fieldErrors.phone }}</p>
          </div>
          <div class="password-field">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password1"
              placeholder="Придумайте пароль *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.password1 }"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              {{ showPassword ? '🔓' : '🔒' }}
            </span>
            <p class="msg" v-if="fieldErrors.password1">{{ fieldErrors.password1 }}</p>
          </div>
          <div class="password-field">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password2"
              placeholder="Пароль ще раз *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.password2 }"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              {{ showPassword ? '🔓' : '🔒' }}
            </span>
            <p class="msg" v-if="fieldErrors.password2">{{ fieldErrors.password2 }}</p>
          </div>
          <div>
            <input
              v-model="firstName"
              type="text"
              placeholder="Ім'я *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.firstName }"
            />
            <p class="msg" v-if="fieldErrors.firstName">{{ fieldErrors.firstName }}</p>
          </div>
          <div>
            <input
              v-model="lastName"
              type="text"
              placeholder="Прізвище *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.lastName }"
            />
            <p class="msg" v-if="fieldErrors.lastName">{{ fieldErrors.lastName }}</p>
          </div>
          <p><input v-model="fatherName" type="text" placeholder="По-батькові" /></p>
          <p><input v-model="city" type="text" placeholder="Місто" /></p>
        </div>

        <!-- Інформація про компанію -->
        <p class="title">Інформація про Вашу компанію</p>
        <div class="fields business__formset">
          <div>
            <input
              v-model="companyName"
              type="text"
              placeholder="Назва компанії *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.companyName }"
            />
            <p class="msg" v-if="fieldErrors.companyName">{{ fieldErrors.companyName }}</p>
          </div>
          <div>
            <input
              v-model="position"
              type="text"
              placeholder="Посада *"
              required
              :class="{ 'input-error': isSubmitted && fieldErrors.position }"
            />
            <p class="msg" v-if="fieldErrors.position">{{ fieldErrors.position }}</p>
          </div>
        </div>

        <button type="submit">Зареєструватись</button>
        <p class="msg error" v-if="fieldErrors.general">{{ fieldErrors.general }}</p>
      </form>
    </div>  
  </div>
</template>

<script>
import apiClient from '@/api/axios';

export default {
  data() {
    return {
      email: '',
      phone: '',
      password1: '',
      password2: '',
      firstName: '',
      lastName: '',
      fatherName: '',
      city: '',
      companyName: '',
      position: '',
      fieldErrors: {},
      isSubmitted: false,
      isLoading: false,
      showPassword: false,
    };
  },
  methods: {
    async handleRegister() {
      this.isLoading = true;
      this.isSubmitted = true;
      this.fieldErrors = {};

      // Перевірка на збіг паролів
      if (this.password1 !== this.password2) {
        this.fieldErrors.password2 = ['Паролі не співпадають'];
        this.isLoading = false;
        return;
      }

      const userData = {
        email: this.email,
        phone: this.phone,
        password: this.password1,
        first_name: this.firstName,
        last_name: this.lastName,
        father_name: this.fatherName,
        city: this.city,
        company_name: this.companyName,
        position: this.position,
      };

      try {
        // Запит на кастомний ендпоінт
        const response = await apiClient.post('/auth/register_partner/', userData);
        console.log('Реєстрація успішна:', response);
        this.$router.push({ name: 'RegistrationConfirmation' });
      } catch (error) {
        console.error('Помилка реєстрації:', error);
        if (error.response?.data) {
          this.fieldErrors = error.response.data;
        } else {
          this.fieldErrors.general = ['Сталася помилка. Спробуйте ще раз.'];
        }
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.input-error {
  border: 1px solid red;
}
.msg {
  color: red;
  font-size: 12px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.password-field {
  position: relative;
}
.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #666;
}

.registration__partner {
    position: relative;
    display: block;
    margin: 0 auto 64px auto;
    max-width: 1200px;
}

.form {
    margin-top: 48px;
}
.title {
    margin: 24px 0;
    font-size: var(--h4);
    font-weight: 500;
}
.fields.business__formset,
.fields.personal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
}
.fields.business__formset > p:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: -1;
}
.fields > p {
    margin-bottom: 0 !important;
}

.fields.business__formset input[type="checkbox"] {
    float: left;
    width: 24px;
}
</style>
