<template>
  <div class="wrapper">
    <div class="products">
      <div class="catalogue__filter-menu">
        <label for="show_filter" class="catalogue__filter-menu__button" @click="toggleSidebar">
        <img src="@/assets/img/icons/filter.png" alt="Фільтр" />
        <span>Фільтри</span>
      </label>
        <div class="sort">
          <select
            v-model="sortOption"
            @change="fetchSortedProducts"
            class="catalogue__filter-menu__select"
          >
            <option value="newest">Новинки</option>
            <option value="popular">Популярні</option>
            <option value="price_asc">Зростання ціни</option>
            <option value="price_dsc">Зменшення ціни</option>
          </select>
        </div>
      </div>

      <transition name="slide">
      <aside v-if="isSidebarOpen" class="sidebar">
        <div class="heading">
          <p class="h3">Фільтри</p>
          <label for="show_filter" @click="toggleSidebar" class="close">
              <img src="@/assets/img/icons/svg/Close.svg" alt="x">
          </label>
      </div>

      <!-- Блок брендів -->

        <ul class="brands-list">
          <h3>Бренди</h3>
          <a v-for="brand in staticBrands" :key="brand.id" :href="brand.url">
            <li>
              <p>{{brand.name}} </p>
            </li>
          </a>
          <h3>Категорії</h3>
          <a v-for="category in staticCategories" :key="category.id" :href="category.url">
            <li>
              <p>{{category.name}} </p>
            </li>
          </a>
          
        </ul>
        
   
      </aside>
    </transition>

      <div class="product__list">
        <ul class="products_ul">
          <li v-for="product in products" :key="product.id" class="product-item">
            <ProductCard :product="product" 
            @OpenWishList="openWish"
            @OpenCartList="openCart"
            />
          </li>
        </ul>
      </div>
      <div class="pagination">
        <a
          :class="{ disabled: currentPage === 1 }"
          @click="goToPage(currentPage - 1)"
          :disabled="currentPage === 1"
        >
          &lt;
        </a>
        <span>|</span>
        <a
          v-for="page in totalPages"
          :key="page"
          :class="{ active: page === currentPage }"
          @click="goToPage(page)"
        >
          {{ page }}
        </a>
        <span>|</span>
        <a
          :class="{ disabled: currentPage === totalPages }"
          @click="goToPage(currentPage + 1)"
          :disabled="currentPage === totalPages"
        >
          &gt;
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchCategoryProducts } from "@/api/productService";
import { fetchSecondLevelCategories } from "@/api/categoryService";

import ProductCard from "@/components/ProductCard.vue";

export default {
  name: "FilterSidebar",
  components: {
    ProductCard
  },
  props: {
    parent_slug: {
      type: String,
      default: null
    },
    child_slug: {
      type: String,
      default: null
    },
    brand_slug: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      products: [],
      isSidebarOpen: false,
      secondLevelCategories: [],
      sortOption: "newest",
      currentPage: 1,
      itemsPerPage: 24,
      totalPages: 1,
    
    staticBrands: [
        {
          id: 1,
          name: "Acnemy",
          url: "/brands/acnemy",
        },
        {
          id: 48,
          name: "Alan Jey",
          url: "/brands/alan-jey",
        },
        {
          id: 5,
          name: "HYALULIP",
          url: "/brands/hyalulip",
        },
        {
          id: 40,
          name: "Orising",
          url: "/brands/orising",
        },
        {
          id: 59,
          name: "Rica",
          url: "/brands/rica",
        },
        {
          id: 7,
          name: "Theramid",
          url: "/brands/theramid",
        },
        {
          id: 3,
          name: "Transparent-Lab",
          url: "/brands/transparent-lab",
        },
      ],
      staticCategories: [
        {
          id: 16,
          name: "Обличчя",
          url: "/catalogue/oblichchia",
        },
        {
          id: 29,
          name: "Волосся",
          url: "/catalogue/volossia",
        },
        {
          id: 165,
          name: "Тіло",
          url: "/catalogue/tilo",
        },
        {
          id: 101,
          name: "Догляд для чоловіків",
          url: "/catalogue/dogliad-dlia-cholovikiv",
        },
      ],
      }},
  created() {
    this.loadCategories();
  },
  watch: {
    brand_slug: "fetchSortedProducts",
    parent_slug: "fetchSortedProducts",
    child_slug: "fetchSortedProducts",
    currentPage: "fetchSortedProducts"
  },
  mounted() {
    this.checkMobileView();
    this.fetchSortedProducts();
    window.addEventListener("resize", this.checkMobileView);
  },
  methods: {
      openWish(){
        this.$emit('OpenWishList');
      },
      openCart(){
        this.$emit('OpenCartList');
      },
      checkMobileView() {
      this.isMobile = window.innerWidth <= 960;
    },
  toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    async loadCategories() {
      try {
        this.secondLevelCategories = await fetchSecondLevelCategories();
      } catch (error) {
        console.error("Помилка завантаження категорій:", error);
      }
    },
    async fetchSortedProducts() {
      try {
        const response = await fetchCategoryProducts({
          page: this.currentPage,
          parent_slug: this.parent_slug,
          child_slug: this.child_slug,
          brand_slug: this.brand_slug,
          sort: this.sortOption
        });

        // Форматуємо дані продуктів
        this.products = response.results.map(product => ({
          id: product.id,
          name: product.name,
          brand: product.brand.name,
          human: product.human,
          url: `/product/${product.slug}`,
          variants: product.variant.map(variant => ({
            id: variant.id,
            code: variant.code,
            value: variant.value,
            price: variant.price,
            discount_price: variant.discount_price,
            images: variant.images,
            url: variant.url,
            whoosale_price: variant.whoosale_price,
            discount_whoosale_price: variant.discount_whoosale_price,
            stock: variant.stock,
            stock_opt: variant.stock_opt
          }))
        }));

        // Встановлюємо кількість сторінок для пагінації
        this.totalPages = Math.ceil(response.count / this.itemsPerPage);
        console.log("Продукти завантажені:", this.products);
      } catch (error) {
        console.error("Помилка завантаження продуктів:", error);
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchSortedProducts();
      }
    }
  }
};
</script>

<style scoped>
:root {
  --filter-width: 272px;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 16px; /* Відстань між фільтрами та продуктами */
}
.wrapper .checkbox {
  display: none;
}
.filters__wrapper {
  width: var(--filter-width);
  margin-right: 32px;
  transition: width 0.2s ease-in-out;
}
.filters {
  display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    margin-bottom: 32px;
}
.filters__wrapper .heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 48px;
}

.filters__wrapper .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  margin-bottom: 24px;
}

.filters .block {
  margin-bottom: 24px;
}

.filters .block label {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding-bottom: 8px;
  border-radius: 8px;
}
.filters .block label p {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 2px;
}
.filters .block label input {
  display: none;
}
.filters .block label .checkbox {
  content: "";
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #ededed;
  border-radius: 4px;
  background-color: white;
  margin-right: 16px;
  transition: all 0.2s ease-in-out;
  min-width: 16px;
}
.catalogue .name {
    margin-right: 4px;
    color: black;
}
.filters .block label:hover {
  background-color: #e6f7ff;
}

.filters .block .checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 8px;
}

.filters .block label input:checked ~ .checkbox {
  background-color: var(--clr-1);
  border-color: var(--clr-1);
}


.filters .block .qty {
  font-size: 0.9em;
  color: grey;
}
.data{
  margin: 16px 0;
}
.price-slider {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.price-slider input[type="number"] {
  width: 60px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  text-align: center;
}

.price-slider .slider {
  display: flex;
  gap: 8px;
}

.catalogue__filter-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}


.catalogue__filter-menu select {
  border-radius: 4px;
  padding: 8px 12px;
  background-color: #f1f1f1;
  font-size: 1em;
  cursor: pointer;
  border: 1px solid #ddd;
  width: 200px;
  transition: background-color 0.2s ease;
}

.catalogue__filter-menu select:hover {
  background-color: #e6f7ff;
}
.products  {
  width: 100%;
}
.products_ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  padding-left: 1px;
  margin-bottom: 32px;
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  padding: 2px;
  overflow: visible;
}

.products__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;
}
@media (max-width:556px){
  .products__list {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
  .products_ul {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
}
.products__list ul.products {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 960px) {
  .wrapper {
    flex-direction: column;
  }
  .filters__wrapper {
    width: 100%;
  }
  .products__list {
    grid-template-columns: 1fr;
  }
}

.price_range_inputs {
    position: reletive;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.block.price input[type=number] {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 4px 4px;
    margin: 0 16px;
    width: 48px;
    border-radius: 8px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: var(--h5);
    -moz-appearance: textfield;
}
.price-slider .slider {
    position: relative;
    display: block;
    width: calc(100% - 8px);
    padding: 0 px;
    margin-left: 4px;
}
.price-slider input {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #dbf8ff;
    height: 4px;
    margin-bottom: 4px;
}
.catalogue .filters ul.scale {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--h6);
    margin-top: 12px;
}
.pagination {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 0;
}
.pagination span,
.pagination a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  margin: 0 3px;
  cursor: pointer;
}
.pagination a {
  background-color: whitesmoke;
  border-radius: 4px;
  text-decoration: none;
}
.pagination a:hover,
.pagination a.active {
  background-color: var(--clr-1);
  color: var(--clr-1-drk);
}
.pagination a.disabled {
  pointer-events: none;
  background-color: whitesmoke;
  color: grey;
}

/* mobile */

.sort select {
  display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0px 12px;
    background-color: whitesmoke;
    border: none;
    font-size: var(--h5);
    cursor: pointer;
    height: 48px;
    width: calc(100% - 24px);

}
.catalogue__filter-menu__button {
      display: none;
    }
.catalogue__filter-menu__button img { 
  width: 24px;
  margin-right: 10px;
}
.catalogue__filter-menu{
  position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}
.sort {
  display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0px 12px;
    background-color: whitesmoke;
    border: none;
    font-size: var(--h5);
    cursor: pointer;
    height: 48px;
    width: calc(30% - 24px);
}
@media (max-width: 960px) {
    .catalogue__filter-menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        margin-top: 15px;
    }
    .sort {
      width: calc(100% - 24px);
    }
    .catalogue__filter-menu__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0px 12px;
    background-color: whitesmoke;
    border: none;
    font-size: var(--h5);
    cursor: pointer;
    height: 48px;
    width: calc(100% - 24px);
}
   

.sort select {
  display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0px 12px;
    background-color: whitesmoke;
    border: none;
    font-size: var(--h5);
    cursor: pointer;
    height: 48px;
    width: calc(100% - 24px);

}
}



/* SIDEBAR */
.sidebar{
  left: 0;
}
.heading{
  padding: 20px;
}
.heading p {
  font-weight: 600;
}
.heading h3 {
  font-size: var(--h4);
  font-weight: 600;
}
.heading .close {
  position: absolute;
  top: 36px;
  right: 24px;
  cursor: pointer;
}
.heading img{
  width: 16px;
  height: 16px;
}
.category-list {
  padding: 20px;
}
              

.brands-list{ 
  padding:20px
}
.brands-list h3{
  margin: 15px 0;
 }
.brands-list li {}
.brands-list li p{
  padding: 2px 5px;
  font-size: 16px;
  font-weight: 300;
}

@media (max-width: 640px) {
    .catalogue__categories, .products__list ul.products {
        grid-template-columns: repeat(2, calc(48% - 6px));
    }
}    
</style>