<template>
  <div class="data profile_data">
    <div class="user__orders">
      <p class="title1">Мої замовлення</p>
      <!-- Анімація завантаження -->
      <div v-if="isLoading" class="loader">Завантаження...</div>
      <!-- Список замовлень -->
      <ul v-else class="orders">
        <li class="heading_wrapper">
          <div class="heading main">
            <p>Статус замовлення</p>
            <p>Тип доставки</p>
            <p>Сума</p>
            <p class="date">Час замовлення</p>
          </div>
        </li>
        <li v-for="order in orders" :key="order.id" class="order">
          <div class="heading">
            <p>{{ order.status }}</p>
            <p>{{ order.pay_type }}</p>
            <p>{{ order.total }} грн</p>
            <p class="date">{{ order.created }}</p>
          </div>
          <ul class="products">
            <p class="bold title">Товари на замовлення</p>
            <li v-for="product in order.products" :key="product.id" class="product">
              <div class="info">
                <a :href="product.variant.brand_url" class="brand">{{ product.variant.brand }}</a>
                <p class="name">{{ product.variant.name }}</p>
                <p class="quantity">{{ product.quantity }} шт. x {{ product.price }} грн</p>
              </div>
              <p class="total">{{ product.total }} грн</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrdersComponent',
  data() {
    return {
      isLoading: true, // Стан для завантаження
    };
  },
  computed: {
    ...mapGetters('orders', ['getOrders']),
    orders() {
      return this.getOrders;
    }
  },
  methods: {
    ...mapActions('orders', ['fetchOrders']),
  },
  async mounted() {
    this.isLoading = true; // Вмикаємо завантаження
    await this.fetchOrders();
    this.isLoading = false; // Вимикаємо завантаження після отримання даних
  }
};
</script>

<style scoped>
.info {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  gap: 50px;
}
.user__orders ul.orders {
    display: grid;
    grid-gap: 32px;
}

/* Loader Styles */
.loader {
  font-size: 1.2em;
  text-align: center;
  margin: 20px 0;
}
.loader::after {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--clr-2);
  animation: loader-bounce 0.6s infinite alternate;
}

@keyframes loader-bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

/* Heading */
.user__orders ul.orders li .heading.main p {
    font-weight: 600;
    font-size: var(--h5) !important;
}

.user__orders ul.orders li .heading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
    border-radius: 16px;
}
.user__orders ul.orders li.order  {
    padding-bottom: 32px;
    border-bottom: 2px solid var(--clr-3);
}

/* Products list */
.user__orders ul.products {
    display: grid;
    grid-gap: 16px;
    margin-top: 24px;
}
.user__orders ul.products .title {
    margin-bottom: 16px;
}
.user__orders ul.products li.product  {
    display: flex;
    align-items: center;
}
.user__orders ul.products li.product .brand,
.user__orders ul.products li.product .name {
    position: relative;
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
}
.user__orders ul.products li.product .brand {
    color: var(--clr-2);
}

@media(max-width: 960px) {
    .heading.main {
        display: none !important;
    }
    .user__orders ul.products li.product {
        grid-template-columns: 1fr;
        grid-template-rows: 80px 1fr 1fr;
    }
}
</style>
