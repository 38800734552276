<template>
  <div class="header__block-nav">
    <div class="content">
      <nav>
        <ul>
          <!-- Динамічне відображення категорій через v-for -->
          <li v-for="category in translatedCategories" :key="category.id">
            <a :href="category.url">{{ category.name }}</a>
          </li>
          
          <!-- Статичний пункт для брендів -->
          <li>
            <a href="/brands">
              <span>{{ $t('header.brands') }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    translatedCategories() {
      return [
        { id: 1, name: this.$t('header.categories.face'), url: "/catalogue/oblichchia/" },
        { id: 2, name: this.$t('header.categories.hair'), url: "/catalogue/volossia/" },
        { id: 3, name: this.$t('header.categories.men_care'), url: "/catalogue/dogliad-dlia-cholovikiv/" },
        { id: 4, name: this.$t('header.categories.body'), url: "/catalogue/tilo/" }
      ];
    }
  }
};
</script>
