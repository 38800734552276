import apiClient  from '@/api/axios';



const state = {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    isWhoosaler: JSON.parse(localStorage.getItem('isWhoosaler')) || false,
    user: null,
};

const mutations = {
    SET_COMPANY(state, company) {
        state.user.company = company;
    },
    SET_REAL_STOCK(state, realStock) {
        if (state.user) {
            state.user.real_stock = realStock;
        }
    },
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token;
        localStorage.setItem('accessToken', token);
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
        localStorage.setItem('refreshToken', token);
    },
    SET_WHOOSALER_STATUS(state, status) {
        state.isWhoosaler = status;
        localStorage.setItem('isWhoosaler', JSON.stringify(status));
    },
    SET_USER(state, user) {
        state.user = user;
    },
    LOGOUT(state) {
        state.accessToken = null;
        state.refreshToken = null;
        state.isWhoosaler = false;
        state.user = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('isWhoosaler');
    },
};

const actions = {
    async toggleRealStock({ commit, state }) {
        try {
            // Змінюємо значення на протилежне
            const newRealStock = !state.user.real_stock;
            const response = await apiClient.patch('/auth/users/me/', { real_stock: newRealStock }, {
                headers: { Authorization: `Bearer ${state.accessToken}` },
            });
            commit('SET_REAL_STOCK', response.data.real_stock); // Оновлюємо значення у Vuex
        } catch (error) {
            console.error('Помилка оновлення значення real_stock:', error);
        }
    },
    async fetchCompany({ commit, state }) {
        try {
            const response = await apiClient.get('/company/company/', {
                headers: { Authorization: `Bearer ${state.accessToken}` },
            });
       
            commit('SET_COMPANY', response.data[0]); // Отримуємо першу компанію користувача
        
        } catch (error) {
            console.error('Помилка отримання інформації про компанію:', error);
        }
    },
    async updateCompany({ commit, state }, companyData) {
        try {
            const response = await apiClient.patch('/company/company/1/', companyData, {
                headers: { Authorization: `Bearer ${state.accessToken}` },
            });
            commit('SET_COMPANY', response.data); // Оновлюємо дані компанії у Vuex
        } catch (error) {
            console.error('Помилка оновлення даних компанії:', error);
            throw error;
        }
    },
    async login({ commit, dispatch }, credentials) {
        try {
            const response = await apiClient.post('/auth/jwt/create/', credentials);
            commit('SET_ACCESS_TOKEN', response.data.access);
            commit('SET_REFRESH_TOKEN', response.data.refresh);
            await dispatch('fetchWhoosalerStatus');
        
        } catch (error) {
            console.error('Помилка авторизації:', error);
            throw new Error('Невірний email або пароль');
        }
    },
    async refreshAccessToken({ commit, state }) {
        if (!state.refreshToken) {
            console.log("Відсутній refreshToken");
            throw new Error("Refresh token not available");
        }
    
        try {
            const response = await apiClient.post('/auth/jwt/refresh/', {
                refresh: state.refreshToken,
            });
            commit('SET_ACCESS_TOKEN', response.data.access);
            console.log("accessToken оновлено");
        } catch (error) {
            console.error("Помилка при оновленні accessToken:", error);
            commit('LOGOUT');
            throw error;
        }
    },    
    async fetchUser({ commit, state }) {
        if (!state.accessToken) return;
        try {
            const response = await apiClient.get('/auth/users/me/', {
                headers: {
                    Authorization: `Bearer ${state.accessToken}`,
                },
            });
            commit('SET_USER', response.data);
        } catch (error) {
            console.error('Помилка отримання користувача:', error);
            if (error.response && error.response.status === 401) {
                commit('LOGOUT');
            }
        }
    },
    async updateUser({ commit, state }, userData) {
        try {
            const response = await apiClient.patch('/auth/users/me/', userData, {
                headers: {
                    Authorization: `Bearer ${state.accessToken}`,
                },
            });
            commit('SET_USER', response.data); // Оновлюємо дані користувача у Vuex
        } catch (error) {
            console.error('Помилка оновлення даних користувача:', error);
            throw error;
        }
    },
    async fetchWhoosalerStatus({ commit, state }) {
        try {
            const response = await apiClient.get('/whoosaler-status/', {
                headers: { Authorization: `Bearer ${state.accessToken}` },
            });
            commit('SET_WHOOSALER_STATUS', response.data.is_whoosaler);
        } catch (error) {
            console.error('Помилка отримання статусу is_whoosaler:', error);
        }
    },
    logout({ commit }) {
        localStorage.removeItem('cart');
        commit('LOGOUT');
    },
    async restorePassword(email) {
        try {
            const response = await apiClient.post('/password/reset/', { email });
            return response.data;
        } catch (error) {
            console.error('Помилка при відновленні пароля:', error);
            throw error;
        }
    },  
};

const getters = {
    isAuthenticated: state => !!state.accessToken,
    getUser: state => state.user,
    isWhoosaler: state => {
        return state.isWhoosaler || JSON.parse(localStorage.getItem('isWhoosaler'));
    },
    getUserCompany: state => state.user?.company || {}, 
    getRealStock: state => state.user ? state.user.real_stock : false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
