<template>
  <div class="header__block-extra">
    <div class="content">
      <!-- Pages -->
      <ul class="header__pages">
        <router-link to="/contacts">
          <li>{{ $t('pages.contacts') }}</li>
        </router-link>
        <router-link to="/page/about">
          <li>{{ $t('pages.about') }}</li>
        </router-link>
        <router-link to="/blog">
          <li>{{ $t('pages.blog') }}</li>
        </router-link>
        <router-link to="/page/delivery">
          <li>{{ $t('pages.delivery') }}</li>
        </router-link>
        <router-link to="/page/return-product">
          <li>{{ $t('pages.return_product') }}</li>
        </router-link>
        <router-link to="/page/payment">
          <li>{{ $t('pages.payment') }}</li>
        </router-link>
        <router-link to="/core/partner">
          <li>{{ $t('pages.become_partner') }}</li>
        </router-link>
        <router-link to="/page/terms-of-use">
          <li>{{ $t('pages.terms_of_use') }}</li>
        </router-link>
      </ul>

      <!-- Languages -->
      <ul class="header__languages">
        <li :class="{ active: currentLanguage === 'ua' }" @click="switchLanguage('ua')"><span>ua</span></li>
        <li :class="{ active: currentLanguage === 'en' }" @click="switchLanguage('en')"><span>en</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderExtra',
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('user-locale', lang); 
    }
  }
};
</script>

<style scoped>
.header__languages li {
  cursor: pointer;
  padding: 0;
}
.header__languages .active {
  font-weight: bold;
  color: var(--clr-primary); 
  text-decoration: underline;
}
.header__pages a li{
  line-height: 1;
}
@media (max-width: 1120px) {
  .header__pages a {
    margin-right: 16px;
  }
}
  @media (max-width: 1064px) {
  .header__pages a {
    margin-right: 14px;
    font-size: 12px;
  }
}
@media (max-width: 1026px) {
  .header__pages a {
    margin-right: 12px;
  }
}
</style>
