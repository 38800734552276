<template>
  <div class="home-slider">
    <div class="content">
      <Carousel ref="carousel" :autoplay="2000" :wrap-around="true" :loop="true" :transition="500" :navigationEnabled="true">
        <Slide v-for="(slide, index) in banners" :key="index">

          <div class="home-slider__slide">
            <img class="lazy banner__pc" :src="slide.pc?.image" :alt="slide.title" v-if="slide.pc" />
            <img class="lazy banner__mobile" :src="slide.mobile?.image" :alt="slide.title" v-if="slide.mobile" />

            <div class="data">
              <div class="text">
                <span class="title" v-if="slide.title">{{ slide.title }}</span>
                <span class="description" v-if="slide.description">{{ slide.description }}</span>
              </div>
              <div v-if="slide.link" class="button-container">
                <a class="button" :href="slide.link">Детальніше</a>
              </div>
            </div>
          </div>
        </Slide>

        <template #addons>
          <div class="buttons">
            <button class="home-slider__button prev" @click="prevSlide">
              <img src="@/assets/img/icons/arrow-prev.png" alt="<" />
            </button>
            <button class="home-slider__button next" @click="nextSlide">
              <img src="@/assets/img/icons/arrow-next.png" alt=">" />
            </button>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import { fetchBanners } from '@/api/bannerService';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'HomeSlider',
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const carousel = ref(null);
    const banners = ref([]);

    const loadBanners = async () => {
      try {
        const response = await fetchBanners();
        banners.value = response.data;
      } catch (error) {
        console.error('Помилка при завантаженні банерів:', error);
      }
    };

    onMounted(() => {
      loadBanners();
    });

    const nextSlide = () => {
      if (carousel.value) {
        carousel.value.next();
      }
    };

    const prevSlide = () => {
      if (carousel.value) {
        carousel.value.prev();
      }
    };

    return {
      carousel,
      banners,
      nextSlide,
      prevSlide,
    };
  },
});
</script>

<style scoped>
.home-slider {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 40px;
}

.home-slider__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.banner__pc {
  display: block;
  width: 100%;
}

.banner__mobile {
  display: none;
}
.content {
  position: relative;
  width: calc(100% - 128px);
  margin: 0 auto;
}
@media (max-width: 768px) {
  .banner__pc {
    display: none;
  }
  .banner__mobile {
    display: block;
    width: 100%;
  }
  .content {
    width: 100%;
  }
}



.home-slider .data {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: 32px;
    padding: 32px;
    width: 30px;
    height: calc(100% - 5px);
    top: 0;
    left: 0;
}

.home-slider .data .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

.home-slider .data .text .title {
    font-size: var(--h2);
    font-weight: 500;
    margin-bottom: 12px;
}

.home-slider .data .text .description {
    font-size: var(--h5);
    font-weight: 500;
    color: grey;
}

/* Розташування кнопки в лівому нижньому куті */
.home-slider .data .button-container {
    justify-self: flex-end !important;
    align-self: flex-end !important;
}

.home-slider__slide.logo-slide .data {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 64px);
    height: calc(100% - 64px);
    top: 0;
}

.home-slider__slide.logo-slide img {
    position: relative;
    display: block;
    width: 320px;
    max-width: 100%;
    height: auto;
    margin-top: -80px;
}

.home-slider .buttons {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: auto;
    right: 32px;
    bottom: 32px;
    z-index: 1;
}

/* Приховуємо кнопки на мобільних пристроях */
@media (max-width: 768px) {
  .home-slider .buttons {
    display: none;
  }
}

.home-slider .buttons button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: white;
    border: none;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.home-slider .buttons button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.home-slider .buttons button img {
    width: 16px;
    height: 16px;
}

.home-slider .buttons button:last-child {
    margin-left: 8px;
}
</style>
