<template>
  <div>
    <!-- Блюр фону -->
    <div v-if="isCartOpen" class="blur-background" @click="closeCart"></div>

    <!-- Sidebar Cart -->
    <div class="sidebar cart right" :class="{ active: isCartOpen }">
      <!-- TITLE -->
      <div class="title">
        <span class="h4">Кошик</span>
        <img class="close" @click="closeCart" src="@/assets/img/icons/close.png" alt="X" />
      </div>
      
      <!-- MAIN -->
      <div class="main">
        <ul id="side_cart_products_list">
          <!-- Список товарів у кошику -->
          <li v-for="item in cartItems" :key="item.id" class="cart__item">
            <a :href="item.url" class="cart__image">
              <div class="wrapper">
                <img :src="item.image || defaultImage" alt="img" />
              </div>
            </a>
            <div class="info">
              <a :href="item.url" class="title">{{ item.name }}</a>
              <p class="cart__price">
                <span v-if="item.discount_price > 0" class="discount_wrapper">
                  <span class="price">{{ item.discount_price }} грн.</span>
                  <span class="discount_price">{{ item.price }} грн.</span>
                </span>
                <span v-else class="price">{{ item.price }} грн.</span>
              </p>
              <div class="counter">
                <button class="action minus" @click="decreaseQuantity(item)">-</button>
                <input
                  class="product_quantity"
                  type="number"
                  v-model.number="item.quantity"
                  min="1"
                  max="10000"
                  @input="updateQuantity({ productId: item.id, quantity: item.quantity })"
                />
                <button class="action plus" @click="increaseQuantity(item)">+</button>
              </div>
              <span class="remove" @click="removeFromCart(item.id)">
                <img src="@/assets/img/icons/svg/Garbage.svg" alt="x">
              </span>
            </div>
          </li>
        </ul>
      </div>
      
      <!-- ACTIONS -->
      <div class="actions">
        <div class="total">
          <span>Всього: {{ cartTotalPrice }} грн.</span>
        </div>
        <a href="/order">
          <button class="confirm">Оформити замовлення</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    isCartOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      defaultImage: require('@/assets/img/no_image.png')
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItems', 'cartTotalPrice'])
  },
  methods: {
    ...mapActions('cart', ['removeFromCart', 'updateQuantity']),
    closeCart() {
      this.$emit('close');
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        this.updateQuantity({ productId: item.id, quantity: item.quantity - 1 });
      }
    },
    increaseQuantity(item) {
      this.updateQuantity({ productId: item.id, quantity: item.quantity + 1 });
    }
  }
};
</script>

  
  <style scoped>
  @import '@/assets/css/shop/cart.css';
  
  /* Стилі для блюра фону */
  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* напівпрозорий фон */
    z-index: 1000;
    backdrop-filter: blur(10px); /* розмиття */
  }
  
  /* Стилі для Sidebar */
  .sidebar {
    z-index: 1001; /* щоб бути поверх блюра */
  }
  </style>
  