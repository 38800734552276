<template>
  <div>
    <!-- Блок "About" -->
    <div class="home-about">
      <div class="content">
        <img :src="logo" alt="Company logo" class="logo" />
      </div>
    </div>

    <!-- Блок "Advantages" -->
    <div class="home-advantages">
      <div class="content">
        <p class="title1 center">Переваги співпраці з нами</p>
        <ul>
          <li v-for="(advantage, index) in advantages" :key="index">
            <div class="content img">
              <img :src="advantage.image" :alt="advantage.title" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
  props: {
    slogan: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    advantages: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style> 
.home-advantages img {
  max-width: 210px;
  max-height: 250px;
}
@media (max-width: 640px){
  .home-advantages img {
    max-width: 530px;
    height: 630px;
  }
}

</style>