<template>
    <div class="content">
      <div class="breadcrumbs">
        <router-link to="/">Головна</router-link>
        <span class="slash"> / </span>
        <router-link to="/brands">Бренди</router-link>
        <span v-if="brandName" class="slash"> / </span>
        <h6 v-if="brandName"> {{ brandName }} </h6>
      </div>
  
      <div class="brands">
        <div v-if="!slug" class="title1">Бренди</div>
  
        <ul v-if="!slug" class="brands-list">
          <router-link
            :to="`/brands/${brand.slug}`"
            v-for="brand in brandData"
            :key="brand.id"
          >
            <li>
              <div class="image">
                <img :src="brand.image" :alt="brand.name" />
              </div>
            </li>
          </router-link>
        </ul>
  
        <div v-else class="brand-details">
          <div class="heading">
            <h1><span class="h1">{{ brandName }}</span></h1>
          </div>
  
          <div class="brand_description">
            <div class="image">
              <img :src="brandImage" :alt="brandName" />
            </div>
            <div class="info richtext">
              <p v-html="brandDescription"></p>
            </div>
          </div>
        </div>
        
        <!-- Передаємо оновлений brand_slug в FilterSidebar -->
        <FilterSidebar :brand_slug="slug" @OpenWishList="openWish" @OpenCartList="openCart" />
      </div>
    </div>
  </template>
  
  <script>
  import { fetchAllBrands, fetchBrandBySlug } from "@/api/brandService";
  import FilterSidebar from "@/components/FilterSidebar.vue";
  
  export default {
    name: "BrandsPage",
    props: {
      slug: String,
    },
    components: {
      FilterSidebar,
    },
    data() {
      return {
        brandData: [],
        brandName: "",
        brandDescription: "",
        brandImage: "",
      };
    },
    created() {
      this.fetchBrandData();
    },
    methods: {
      openWish(){
        this.$emit('OpenWishList');
      },
      openCart(){
        this.$emit('OpenCartList');
      },
      async fetchBrandData() {
        try {
          if (!this.slug) {
            const response = await fetchAllBrands();
            this.brandData = response;
            this.brandName = '';
          } else {
            const response = await fetchBrandBySlug(this.slug);
            this.brandName = response.name;
            this.brandDescription = response.description;
            this.brandImage = response.image;
          }
        } catch (error) {
          console.error("Помилка завантаження даних бренду:", error);
        }
      },
    },
    watch: {
      slug: {
        immediate: true,
        handler() {
          this.fetchBrandData();
        },
      },
    },
  };
  </script>
  



  <style scoped>
  .brands ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 24px;
  }
  .brands li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 4px;
    padding: 16px;
    transition: all 0.2s ease-in-out;
}
  .breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    margin-bottom: 16px;
  }
  .breadcrumbs .slash {
    margin: 0 4px;
    color: #ccc;
  }
  .content.brands .title1 {
    font-size: 1.5em;
    margin-bottom: 24px;
  }
  .content.brands ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 24px;
  }
  .content.brands li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 4px;
    padding: 16px;
    transition: all 0.2s ease-in-out;
  }
  .image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 50%;
  }


.brands-list img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
}
.richtext {
    position: relative;
    display: block;
    margin-top: 24px;
    line-height: 2;
}
.brand_description {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-gap: 48px;
    margin-bottom: 80px;
}
@media(max-width: 960px) {
    .brand_description {
        grid-template-columns: 1fr;
    }
}

.brand_description .image {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 30px;
}
.brand_description .image img {
    position: relative;
    width: 100%;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    border-radius: 8px;
}
.brand_description .info {
    height: auto;
    max-height: 480px;
    overflow-y: auto;
}

.brand_description .info p {
    color: grey;
}


.brand_description .info::-webkit-scrollbar {
    width: 2px;
    background-color: whitesmoke;
}


.brand_description .info::-webkit-scrollbar-thumb {
    background-color: var(--clr-1);
    outline: 1px solid var(--clr-1);
}


.heading .slash {
    color: rgb(224, 224, 224);
    margin: 0 12px;
    font-size: var(--h1);
}

.richtext h1, .richtext *:first-child {
    color: black;
}
.richtext h1,
.richtext h2 {
    color: #000;
    font-weight: bold; /* Якщо потрібно підкреслити жирність */
}


/* Не перевіреню */
.product-list ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #ddd;
  padding: 8px 12px;
  margin: 0 4px;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

  </style>
  